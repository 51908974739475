//src/pages/presentation/auth/Login.tsx
import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
// import FormGroup from '../../../components/bootstrap/forms/FormGroup';
// import Input from '../../../components/bootstrap/forms/Input';
// import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
// import Spinner from '../../../components/bootstrap/Spinner';
import GoogleProvider from '../../../apply/GoogleOAuthProvider';
import { GoogleLogin } from '@react-oauth/google';
import { apiCall } from '../../../api';
import { setToken, setUserId } from '../../../localStorage';
import ReactGA from 'react-ga4';

interface ILoginHeaderProps {
  isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
  if (isNewUser) {
    return (
      <>
        <div className='text-center h1 fw-bold mt-5'>Create Account,</div>
        <div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
      </>
    );
  }
  return (
    <>
      <div className='text-center h1 fw-bold mt-5'>Welcome,</div>
      <div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
    </>
  );
};
LoginHeader.defaultProps = {
  isNewUser: false,
};

interface ILoginProps {
  isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {

	// return text We are down due to high demand

	// return (
	// 	<PageWrapper title={'Temporary Down'}>
	// 		<Page>
	// 			<div className='row d-flex justify-content-center my-4'>
	// 				<div className='col-auto'>
	// 					<Logo width={100} />
	// 				</div>
	// 			</div>
	// 			<div className='row d-flex align-items-center h-100'>
	// 				<div
	// 					className='col-12 d-flex justify-content-center text-center'
	// 					style={{ fontSize: 'calc(1.5rem + 1.5vw)' }}>
	// 					<p>Due to high demand, we are currently down.<br /> Is it a problem or is it luck?</p>
	// 				</div>
	// 			</div>
	// 		</Page>
	// 	</PageWrapper>
	// );
		
	const { setUser } = useContext(AuthContext);

  const { darkModeStatus } = useDarkMode();

  const [singUpStatus/** , setSingUpStatus*/] = useState<boolean>(!!isSignUp);
  const [isGAEventTriggered, setIsGAEventTriggered] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate('/'), [navigate]);

  const usernameCheck = (username: string) => {
    return !!getUserDataWithUsername(username);
  };

  const passwordCheck = (username: string, password: string) => {
    return getUserDataWithUsername(username).password === password;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loginUsername: USERS.JOHN.username,
      loginPassword: USERS.JOHN.password,
    },
    validate: (values) => {
      const errors: { loginUsername?: string; loginPassword?: string } = {};

      if (!values.loginUsername) {
        errors.loginUsername = 'Required';
      }

      if (!values.loginPassword) {
        errors.loginPassword = 'Required';
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: (values) => {
      if (usernameCheck(values.loginUsername)) {
        if (passwordCheck(values.loginUsername, values.loginPassword)) {
          if (setUser) {
            setUser(values.loginUsername);
          }

          handleOnClick();
        } else {
          formik.setFieldError('loginPassword', 'Username and password do not match.');
        }
      }
    },
  });

  const handleGoogleLoginSuccess = async (response: any) => {
    const token = response.credential;
    console.log(response.credential);
    setToken(token);

    try {
      const data = await apiCall('/auth/login');

      data.sub = isNaN(Number(data.sub)) ? data.sub : Number(data.sub);

      const user = {
        valid: data.valid,
        name: data.name,
        email: data.email,
        picture: data.picture,
        sub: data.sub,
      };

      if (user.sub) {
        ReactGA.set({ user_id: user.sub });
        setUserId(user.sub);
      }

      ReactGA.event({
        category: 'login_success',
        action: 'login_success',
        value: user.sub
      });

      console.log(user);

      if (!user.valid) {
        throw new Error('User is not valid');
      }

      if (setUser) {
        setUser(user.name);
      }

      navigate('/');
    } catch (error) {
      ReactGA.event({
        category: 'login_failed',
        action: 'login_failed',
        label: 'Google login failed',
      });
      console.log('Google login failed:', error);
      // clean up token
      setToken('');
      navigate('/auth-pages/login');
      //
    }
  };

  const handleGoogleLoginClick = () => {
    if (!isGAEventTriggered) {
      ReactGA.event({
        category: 'login_button_hover',
        action: 'login_button_hover',
      });
      setIsGAEventTriggered(true);
    }
  };

  return (
    <PageWrapper
      isProtected={false}
      title={singUpStatus ? 'Sign Up' : 'Login'}
      className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
      <Page className='p-0'>
        <div className='row h-100 align-items-center justify-content-center'>
          <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
            <Card className='shadow-3d-dark' data-tour='login-page'>
              <CardBody>
                <div className='text-center my-5'>
                  <Link
                    to='/'
                    className={classNames(
                      'text-decoration-none  fw-bold display-2',
                      {
                        'text-dark': !darkModeStatus,
                        'text-light': darkModeStatus,
                      },
                    )}
                    aria-label='Apply'>
                    <Logo width={200} />
                  </Link>
                </div>
                <LoginHeader isNewUser={singUpStatus} />

                <div className='d-flex justify-content-center mb-4' onMouseEnter={handleGoogleLoginClick}>
                  <GoogleProvider>
                      <GoogleLogin
                        onSuccess={handleGoogleLoginSuccess}
                        // onError={() => {handleGoogleLoginFailure}}
                        theme={darkModeStatus ? 'filled_blue' : 'filled_blue'}
                        size={'large'}
                        shape={'rectangular'}
                      />
                  </GoogleProvider>
                </div>

              </CardBody>
            </Card>
            <div className='text-center'>
              <a
                href='/'
                className={classNames('text-decoration-none me-3', {
                  'link-light': darkModeStatus,
                  'link-dark': !darkModeStatus,
                })}>
                Privacy policy
              </a>
              <a
                href='/'
                className={classNames('link-light text-decoration-none', {
                  'link-light': darkModeStatus,
                  'link-dark': !darkModeStatus,
                })}>
                Terms of use
              </a>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
Login.propTypes = {
  isSignUp: PropTypes.bool,
};
Login.defaultProps = {
  isSignUp: false,
};

export default Login;
