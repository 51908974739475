import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { TourProvider } from '@reactour/tour';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ToastContainer } from 'react-toastify';
import ThemeContext from '../contexts/themeContext';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import AsideRoutes from '../layout/Aside/AsideRoutes';
import { ToastCloseButton } from '../components/bootstrap/Toasts';
import ReactGA from 'react-ga4';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../components/bootstrap/Modal';
import Button from '../components/bootstrap/Button';
import { useLocation } from 'react-router-dom';  // Import useLocation
import { getUserId } from '../localStorage';

const App = () => {
    const location = useLocation();  // Get the current location
    getOS();
    ReactGA.initialize('G-FT8EGXDP6B', {
        gaOptions: {
            consent: {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            }
        }
    });

    if (process.env.NODE_ENV !== 'production') {
        ReactGA.set({ traffic_type: 'internal' });
    }

    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);

    /**
     * Dark Mode
     */
    const { themeStatus, darkModeStatus } = useDarkMode();
    const theme = {
        theme: themeStatus,
        primary: COLORS.PRIMARY.code,
        secondary: COLORS.SECONDARY.code,
        success: COLORS.SUCCESS.code,
        info: COLORS.INFO.code,
        warning: COLORS.WARNING.code,
        danger: COLORS.DANGER.code,
        dark: COLORS.DARK.code,
        light: COLORS.LIGHT.code,
    };

    useEffect(() => {
        if (darkModeStatus) {
            document.documentElement.setAttribute('theme', 'dark');
            document.documentElement.setAttribute('data-bs-theme', 'dark');
        }
        return () => {
            document.documentElement.removeAttribute('theme');
            document.documentElement.removeAttribute('data-bs-theme');
        };
    }, [darkModeStatus]);

    /**
     * Full Screen
     */
    const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
    const ref = useRef(null);
    useFullscreen(ref, fullScreenStatus, {
        onClose: () => setFullScreenStatus(false),
    });

    /**
     * Modern Design
     */
    useLayoutEffect(() => {
        if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
            document.body.classList.add('modern-design');
        } else {
            document.body.classList.remove('modern-design');
        }
    }, []);

    /**
     * Cookie Consent
     */
    const [cookieConsent, setCookieConsent] = useState(localStorage.getItem('cookieConsent'));

    const handleAccept = () => {
        ReactGA.gtag('consent', 'update', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
            ad_personalization: 'granted',
            ad_user_data: 'granted',            
        });
        localStorage.setItem('cookieConsent', 'accepted');
        setCookieConsent('accepted');

		ReactGA.event({
			category: 'Cookie Consent',
			action: 'cookie_consent_accepted'
		});
    };

    const handleDecline = () => {
        ReactGA.gtag('consent', 'update', {
            ad_storage: 'denied',
            analytics_storage: 'denied',
            ad_personalization: 'denied',
            ad_user_data: 'denied',
        });
        localStorage.setItem('cookieConsent', 'declined');
        setCookieConsent('declined');

		ReactGA.event({
			category: 'Cookie Consent',
			action: 'cookie_consent_declined'
		});
    };

	useEffect(() => {
        const userId = getUserId();

        if (userId) {
            ReactGA.set({ user_id: userId });
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <TourProvider steps={steps} styles={styles} showNavigation={false} showBadge={false}>
                <div
                    ref={ref}
                    className='app'
                    style={{
                        backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
                        zIndex: fullScreenStatus ? 1 : undefined,
                        overflow: fullScreenStatus ? 'scroll' : undefined,
                    }}>
                    <AsideRoutes />
                    <Wrapper />
                </div>
                <Portal id='portal-notification'>
                    <ReactNotifications />
                </Portal>
                <ToastContainer closeButton={ToastCloseButton} toastClassName='toast show' />
            </TourProvider>

            {!cookieConsent && location.pathname !== '/hr-booking' && (
                <Modal
                    isOpen={true}
                    setIsOpen={() => {}}
                    size='lg'>
                    <ModalHeader setIsOpen={() => {}}>
                        <ModalTitle id='modalhead'>Cookie Consent</ModalTitle>
                    </ModalHeader>
                    <ModalBody className='aaa'>
                        To provide a better experience, we use cookies. Please give your consent for us to use cookies.
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='info'
                            isOutline
                            className='border-0'
                            onClick={handleDecline}>
                            Decline
                        </Button>
                        <Button
                            color='success'
                            icon='Check'
                            onClick={handleAccept}>
                            Accept
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </ThemeProvider>
    );
};

export default App;
