// src/apply/GoogleOAuthProvider.js
import { GoogleOAuthProvider } from '@react-oauth/google';

const GoogleProvider = ({ children }) => {
  const clientId = '13435461002-fgams28e4m22f3lh4vr8ca8o90rujjp1.apps.googleusercontent.com'; // Replace with your Google client ID

  return (
    <GoogleOAuthProvider clientId={clientId}>
      {children}
    </GoogleOAuthProvider>
  );
};

export default GoogleProvider;
