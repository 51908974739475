// src/localStorage.ts

const getToken = (): string | null => {
  return localStorage.getItem('token');
};

const setToken = (token: string): void => {
  localStorage.setItem('token', token);
};

const removeToken = (): void => {
  localStorage.removeItem('token');
};


const getUserId = (): string | null => {
  return localStorage.getItem('userId');
};

const setUserId = (userId: string): void => {
  localStorage.setItem('userId', userId);
};

const removeUserId = (): void => {
  localStorage.removeItem('userId');
};

export { getToken, setToken, removeToken, getUserId, setUserId, removeUserId };