import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
<svg version="1.1" id="Layer_1" x="0px" y="0px"
	 width="100%" viewBox="0 0 1275 464" enable-background="new 0 0 1275 464" >
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M980.000000,465.000000 
	C653.333374,465.000000 327.166718,465.000000 1.000044,465.000000 
	C1.000029,310.333374 1.000029,155.666718 1.000015,1.000065 
	C425.999969,1.000043 850.999939,1.000043 1276.000000,1.000021 
	C1276.000000,155.666611 1276.000000,310.333221 1276.000000,464.999908 
	C1177.500000,465.000000 1079.000000,465.000000 980.000000,465.000000 
M918.500000,445.000214 
	C1031.255371,445.000214 1144.010864,445.000214 1256.696289,445.000214 
	C1256.696289,303.011353 1256.696289,161.615005 1256.696289,20.296171 
	C844.339600,20.296171 432.286804,20.296171 20.303921,20.296171 
	C20.303921,161.989166 20.303921,303.385498 20.303921,445.000214 
	C319.572052,445.000214 618.536011,445.000214 918.500000,445.000214 
z"/>
<path fill="#1D2435" opacity="1.000000" stroke="none" 
	d="
M918.000000,445.000214 
	C618.536011,445.000214 319.572052,445.000214 20.303921,445.000214 
	C20.303921,303.385498 20.303921,161.989166 20.303921,20.296171 
	C432.286804,20.296171 844.339600,20.296171 1256.696289,20.296171 
	C1256.696289,161.615005 1256.696289,303.011353 1256.696289,445.000214 
	C1144.010864,445.000214 1031.255371,445.000214 918.000000,445.000214 
M657.393738,369.941437 
	C661.522034,365.527649 664.913513,360.713440 665.159302,354.395325 
	C665.488892,345.924011 665.810730,337.449646 665.931519,328.973694 
	C666.063293,319.728210 665.961487,310.479370 665.961487,300.173492 
	C669.016357,302.689575 671.083801,304.752380 673.480530,306.307770 
	C688.798584,316.248962 705.501892,319.557343 723.592712,317.074066 
	C746.424377,313.940033 764.567505,303.024933 778.089844,284.609192 
	C792.350708,265.187622 798.019836,243.486679 795.149841,219.334869 
	C792.715515,198.849304 784.848938,181.097931 770.119385,166.851166 
	C757.112732,154.270874 741.335938,146.789688 723.121094,144.711365 
	C703.875488,142.515472 686.441345,146.735703 671.006409,158.612503 
	C669.546875,159.735580 668.197815,161.002304 666.523987,162.436661 
	C664.380920,149.873032 653.789307,139.985245 638.719177,140.882782 
	C626.760376,141.595032 615.627991,151.375046 615.029297,163.327835 
	C614.613342,171.632843 614.659302,179.966171 614.671814,188.286591 
	C614.754333,243.258530 614.881409,298.230377 615.000916,353.202271 
	C615.013672,359.068970 617.162354,364.023499 620.957886,368.523956 
	C628.689392,377.691376 646.134094,379.586060 657.393738,369.941437 
M547.734863,162.736206 
	C524.396545,144.665329 498.691467,139.070923 470.631714,149.323029 
	C462.943634,152.131989 456.146027,157.378098 449.204254,161.354324 
	C448.266571,154.149429 444.053864,147.713226 436.804535,143.667648 
	C418.706329,133.567780 397.716522,146.159882 397.815582,165.121460 
	C398.130737,225.438141 397.899170,285.757629 397.909180,346.076019 
	C397.909851,350.063873 397.557098,354.187012 398.410065,358.015228 
	C401.505402,371.907166 412.886719,377.635132 424.368256,376.811920 
	C438.457672,375.801727 447.948181,365.516235 447.987488,352.281250 
	C448.035522,336.118317 447.994354,319.955170 448.014130,303.792145 
	C448.015625,302.586243 448.196716,301.380554 448.345886,299.537201 
	C451.293701,302.005188 453.502472,304.268921 456.088654,305.953094 
	C474.365112,317.854675 494.156525,320.846100 515.169312,315.312958 
	C559.102234,303.744415 583.970520,260.839081 577.801941,215.936661 
	C574.884216,194.698425 564.823914,177.002686 547.734863,162.736206 
M304.557556,148.042648 
	C301.592468,152.810654 298.627411,157.578674 295.899658,161.965073 
	C294.721283,160.945084 293.092468,159.319092 291.255371,157.978592 
	C273.428925,144.971085 253.518036,141.712631 232.410828,146.654419 
	C187.450775,157.180817 163.810410,200.286987 170.261002,243.645126 
	C173.806717,267.477905 184.664536,287.554413 204.482315,301.867523 
	C223.482605,315.590210 244.533218,321.343903 267.927124,316.078400 
	C279.048553,313.575134 288.768250,308.536743 296.817169,299.848480 
	C300.700348,307.441589 306.619171,312.192719 314.265289,314.718658 
	C331.558533,320.431549 349.913757,308.575775 349.958038,291.491333 
	C350.068146,249.004715 350.064362,206.517426 349.930634,164.030960 
	C349.908386,156.971909 347.034729,150.746979 341.356628,146.356598 
	C330.059723,137.621674 316.817963,138.158081 304.557556,148.042648 
M1035.510132,321.007599 
	C1053.650146,285.220917 1071.860474,249.469620 1089.890869,213.627823 
	C1095.946533,201.589996 1102.169067,189.571930 1107.168213,177.085541 
	C1111.765503,165.602600 1107.867920,153.657379 1099.770020,147.821136 
	C1086.869507,138.523499 1067.557007,141.937927 1059.308594,158.338852 
	C1047.965820,180.892502 1036.507324,203.387833 1025.110107,225.913986 
	C1021.373962,233.298325 1017.673889,240.700851 1013.719055,248.569214 
	C1010.912048,242.755005 1008.433472,237.630798 1005.963196,232.502594 
	C993.929382,207.521072 981.810303,182.580124 969.893677,157.542831 
	C960.786194,138.407730 934.503967,138.553299 924.802612,153.799820 
	C918.721985,163.356064 919.907349,173.277573 924.524048,182.837387 
	C944.001587,223.169373 963.656128,263.416077 983.361816,303.637207 
	C984.543091,306.048279 984.818848,307.798981 983.561096,310.297302 
	C978.220398,320.905945 972.820923,331.507233 968.078003,342.388031 
	C963.742798,352.333466 966.733826,363.589813 974.427490,370.092010 
	C986.165466,380.012146 1005.584534,377.471344 1013.893677,362.720428 
	C1021.450623,349.304779 1028.040894,335.344574 1035.510132,321.007599 
M832.003235,226.500000 
	C832.003296,248.832703 831.930725,271.165924 832.089111,293.497498 
	C832.110535,296.515564 832.886475,299.686554 834.008423,302.510468 
	C838.277527,313.255859 845.437561,317.146729 859.868774,316.999908 
	C869.625000,316.900665 881.001160,306.906097 881.942078,297.304779 
	C882.525024,291.356415 882.900330,285.361664 882.909180,279.387054 
	C882.994812,221.555817 882.914368,163.724243 883.073792,105.893280 
	C883.097412,97.331680 879.546814,90.946793 872.791260,86.451691 
	C864.038330,80.627563 854.532349,80.009933 845.194702,85.003281 
	C836.368774,89.722992 831.963623,97.321373 831.979126,107.503670 
	C832.038940,146.835693 832.003296,186.167877 832.003235,226.500000 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M657.105286,370.167236 
	C646.134094,379.586060 628.689392,377.691376 620.957886,368.523956 
	C617.162354,364.023499 615.013672,359.068970 615.000916,353.202271 
	C614.881409,298.230377 614.754333,243.258530 614.671814,188.286591 
	C614.659302,179.966171 614.613342,171.632843 615.029297,163.327835 
	C615.627991,151.375046 626.760376,141.595032 638.719177,140.882782 
	C653.789307,139.985245 664.380920,149.873032 666.523987,162.436661 
	C668.197815,161.002304 669.546875,159.735580 671.006409,158.612503 
	C686.441345,146.735703 703.875488,142.515472 723.121094,144.711365 
	C741.335938,146.789688 757.112732,154.270874 770.119385,166.851166 
	C784.848938,181.097931 792.715515,198.849304 795.149841,219.334869 
	C798.019836,243.486679 792.350708,265.187622 778.089844,284.609192 
	C764.567505,303.024933 746.424377,313.940033 723.592712,317.074066 
	C705.501892,319.557343 688.798584,316.248962 673.480530,306.307770 
	C671.083801,304.752380 669.016357,302.689575 665.961487,300.173492 
	C665.961487,310.479370 666.063293,319.728210 665.931519,328.973694 
	C665.810730,337.449646 665.488892,345.924011 665.159302,354.395325 
	C664.913513,360.713440 661.522034,365.527649 657.105286,370.167236 
M743.989929,221.807587 
	C739.240479,202.628845 721.504028,191.331665 704.224609,191.807495 
	C678.605164,192.512970 659.926880,217.783752 667.716003,242.277649 
	C674.041992,262.170746 694.726990,273.472137 715.370605,267.827209 
	C735.510803,262.319946 748.500793,242.291336 743.989929,221.807587 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M548.039062,162.921295 
	C564.823914,177.002686 574.884216,194.698425 577.801941,215.936661 
	C583.970520,260.839081 559.102234,303.744415 515.169312,315.312958 
	C494.156525,320.846100 474.365112,317.854675 456.088654,305.953094 
	C453.502472,304.268921 451.293701,302.005188 448.345886,299.537201 
	C448.196716,301.380554 448.015625,302.586243 448.014130,303.792145 
	C447.994354,319.955170 448.035522,336.118317 447.987488,352.281250 
	C447.948181,365.516235 438.457672,375.801727 424.368256,376.811920 
	C412.886719,377.635132 401.505402,371.907166 398.410065,358.015228 
	C397.557098,354.187012 397.909851,350.063873 397.909180,346.076019 
	C397.899170,285.757629 398.130737,225.438141 397.815582,165.121460 
	C397.716522,146.159882 418.706329,133.567780 436.804535,143.667648 
	C444.053864,147.713226 448.266571,154.149429 449.204254,161.354324 
	C456.146027,157.378098 462.943634,152.131989 470.631714,149.323029 
	C498.691467,139.070923 524.396545,144.665329 548.039062,162.921295 
M476.008759,193.205170 
	C454.795746,200.042099 444.899231,221.227036 448.811829,238.386765 
	C453.242462,257.818604 470.512299,271.121704 491.232391,268.859528 
	C511.036987,266.697296 526.158020,251.642670 526.904541,230.845642 
	C527.827148,205.142914 502.971588,186.640869 476.008759,193.205170 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M304.809631,147.780930 
	C316.817963,138.158081 330.059723,137.621674 341.356628,146.356598 
	C347.034729,150.746979 349.908386,156.971909 349.930634,164.030960 
	C350.064362,206.517426 350.068146,249.004715 349.958038,291.491333 
	C349.913757,308.575775 331.558533,320.431549 314.265289,314.718658 
	C306.619171,312.192719 300.700348,307.441589 296.817169,299.848480 
	C288.768250,308.536743 279.048553,313.575134 267.927124,316.078400 
	C244.533218,321.343903 223.482605,315.590210 204.482315,301.867523 
	C184.664536,287.554413 173.806717,267.477905 170.261002,243.645126 
	C163.810410,200.286987 187.450775,157.180817 232.410828,146.654419 
	C253.518036,141.712631 273.428925,144.971085 291.255371,157.978592 
	C293.092468,159.319092 294.721283,160.945084 295.899658,161.965073 
	C298.627411,157.578674 301.592468,152.810654 304.809631,147.780930 
M264.133118,269.003876 
	C265.910004,268.674408 267.713501,268.448547 269.459717,267.999756 
	C290.120789,262.689575 301.416626,244.133942 299.782867,227.525955 
	C297.715759,206.512817 280.094879,191.051926 258.504120,191.748749 
	C231.942017,192.606003 213.216980,219.650024 222.705414,244.455658 
	C229.038452,261.012177 242.525192,269.187256 264.133118,269.003876 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M1035.283691,321.316040 
	C1028.040894,335.344574 1021.450623,349.304779 1013.893677,362.720428 
	C1005.584534,377.471344 986.165466,380.012146 974.427490,370.092010 
	C966.733826,363.589813 963.742798,352.333466 968.078003,342.388031 
	C972.820923,331.507233 978.220398,320.905945 983.561096,310.297302 
	C984.818848,307.798981 984.543091,306.048279 983.361816,303.637207 
	C963.656128,263.416077 944.001587,223.169373 924.524048,182.837387 
	C919.907349,173.277573 918.721985,163.356064 924.802612,153.799820 
	C934.503967,138.553299 960.786194,138.407730 969.893677,157.542831 
	C981.810303,182.580124 993.929382,207.521072 1005.963196,232.502594 
	C1008.433472,237.630798 1010.912048,242.755005 1013.719055,248.569214 
	C1017.673889,240.700851 1021.373962,233.298325 1025.110107,225.913986 
	C1036.507324,203.387833 1047.965820,180.892502 1059.308594,158.338852 
	C1067.557007,141.937927 1086.869507,138.523499 1099.770020,147.821136 
	C1107.867920,153.657379 1111.765503,165.602600 1107.168213,177.085541 
	C1102.169067,189.571930 1095.946533,201.589996 1089.890869,213.627823 
	C1071.860474,249.469620 1053.650146,285.220917 1035.283691,321.316040 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M832.003235,226.000000 
	C832.003296,186.167877 832.038940,146.835693 831.979126,107.503670 
	C831.963623,97.321373 836.368774,89.722992 845.194702,85.003281 
	C854.532349,80.009933 864.038330,80.627563 872.791260,86.451691 
	C879.546814,90.946793 883.097412,97.331680 883.073792,105.893280 
	C882.914368,163.724243 882.994812,221.555817 882.909180,279.387054 
	C882.900330,285.361664 882.525024,291.356415 881.942078,297.304779 
	C881.001160,306.906097 869.625000,316.900665 859.868774,316.999908 
	C845.437561,317.146729 838.277527,313.255859 834.008423,302.510468 
	C832.886475,299.686554 832.110535,296.515564 832.089111,293.497498 
	C831.930725,271.165924 832.003296,248.832703 832.003235,226.000000 
z"/>
<path fill="#1D2435" opacity="1.000000" stroke="none" 
	d="
M744.007507,222.231888 
	C748.500793,242.291336 735.510803,262.319946 715.370605,267.827209 
	C694.726990,273.472137 674.041992,262.170746 667.716003,242.277649 
	C659.926880,217.783752 678.605164,192.512970 704.224609,191.807495 
	C721.504028,191.331665 739.240479,202.628845 744.007507,222.231888 
z"/>
<path fill="#1D2435" opacity="1.000000" stroke="none" 
	d="
M476.415527,193.109070 
	C502.971588,186.640869 527.827148,205.142914 526.904541,230.845642 
	C526.158020,251.642670 511.036987,266.697296 491.232391,268.859528 
	C470.512299,271.121704 453.242462,257.818604 448.811829,238.386765 
	C444.899231,221.227036 454.795746,200.042099 476.415527,193.109070 
z"/>
<path fill="#1D2435" opacity="1.000000" stroke="none" 
	d="
M263.672638,269.006500 
	C242.525192,269.187256 229.038452,261.012177 222.705414,244.455658 
	C213.216980,219.650024 231.942017,192.606003 258.504120,191.748749 
	C280.094879,191.051926 297.715759,206.512817 299.782867,227.525955 
	C301.416626,244.133942 290.120789,262.689575 269.459717,267.999756 
	C267.713501,268.448547 265.910004,268.674408 263.672638,269.006500 
z"/>
</svg>	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
