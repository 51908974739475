// src/api.ts

import { getToken } from './localStorage';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiCall = async (endpoint: string, options: any = {}) => {
  const token = getToken();
  if (!token) {
    throw new Error('Unauthorized');
  }

  const headers = {
    ...options.headers,
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    ...options,
    headers,
  });

  if (response.status === 401) {
    throw new Error('Unauthorized');
  }

  if (!response.ok) {
    throw new Error('API call failed');
  }
  // if response not empty, return response.json()
  if (response.status !== 204) {
    return response.json();
  } else {
    return {};
  }
};

export { apiCall };