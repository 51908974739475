import * as React from "react";
import type { SVGProps } from "react";
const SvgCustomNpm = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#D40001" d="M0 0h24v24H0z" />
      <path fill="#FFF" d="M16.718 7.928h-4.513V20.25H4V3h16v17.249h-3.282z" />
    </g>
  </svg>
);
export default SvgCustomNpm;
